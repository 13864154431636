<template>
  <div class="waning-exceed-detail">
    <div v-if="this.list && this.list.length>=1"
         :style="{height: freshHeight + 180 +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
      <warning-exceed-card :data="this.list" style="margin-bottom: 50px"/>
    </div>
    <div v-else style="background-color: #FFFFFF">
      <van-empty image="error" description="没有数据"/>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import WarningExceedCard from "@/components/Card/WarningExceedCard";
import {getChaoEDetail} from '@/api/warningPanel'


export default {
  name: "WarningExceedDetail",
  components: {Toast, WarningExceedCard},
  data() {
    return {
      freshHeight: '',
      list: [],
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  methods: {
    init(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getChaoEDetail({
      }).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.list = res
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    }
  }
}
</script>

<style scoped>

.waning-exceed-detail{
  background: #FEFDFF;
}

</style>